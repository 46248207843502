<template>
    <div class="app-container">
        <!-- 查询区域 -->

        <!-- 按钮区 -->
        <el-row :gutter="20" style="margin:9px 2px">
            <el-button type="success" icon="el-icon-add" size="min" @click="addButton">新增</el-button>
            <!-- <el-button type="success" icon="el-icon-add" size="min" @click="selectDataListButton">查看</el-button> -->
            <!-- <el-button type="success" icon="el-icon-add" size="min" @click="editButton">编辑</el-button> -->
            <!-- <el-button type="success" icon="el-icon-add" size="min" @click="delectButton" disabled="">删除</el-button> -->
            <!-- <el-button type="success" icon="el-icon-add" size="min" @click="selectButton(this.id)" disabled="">查看</el-button> -->
        </el-row>

        <!--     列表区域   -->
        <el-table :data="dataColumnList">
            <el-table-column label="序号" align="center" width="65">
                <template slot-scope="scope">
                    <el-radio :label="scope.$index" v-model="radio" @change.native="getCurrentRow(scope.row)"></el-radio>
                </template>
            </el-table-column>

            <el-table-column label="名称" align="center" prop="name" />
            <el-table-column label="类型" align="center" prop="type" :formatter="botsTypeFrom" />
            <el-table-column label="最近发送时间" align="center" prop="lastSendAt" />
            <!-- <el-table-column label="创建人" align="center" prop="createdBy" /> -->
            <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                    <el-button size="mini" type="text" icon="el-icon-delete" @click="setInfomaNotificaButton(scope.row)"
                        >配置内容</el-button>
                    <el-button size="mini" type="text" icon="el-icon-delete" @click="selectButton(scope.row)"
                        >查看</el-button>
                    <el-button size="mini" type="text" icon="el-icon-delete" @click="selectButton(scope.row)"
                        disabled="">发送</el-button>
                    <el-button size="mini" type="text" icon="el-icon-delete" @click="editButton(scope.row)"
                        >编辑</el-button>
                </template>
            </el-table-column>
        </el-table>

        <pagination :total="queryParams.total" :page-size.sync="queryParams.pageSize"
            :current-page.sync="queryParams.currentPage" />
        <notificationbots ref="notificationbotsFrom" />
        <notificaShow ref="informaNotificaFrom"/>
    </div>
</template>


<script>
import {
    botsListApi,
} from "@/api/work/bots/bots.js";
import notificationbots from './modules/addEditnotificationbots.vue';
import notificaShow from './modules/notificaShow.vue';
export default {
    components: {
        notificationbots,
        notificaShow
    },
    data() {
        return {
            // 选中数组
            radio: null,
            ids: null,
            // 查询参数
            queryParams: {
                currentPage: 1,
                pageSize: 10,
                total: 0,
            },
            total: 0,
            // 表单参数
            form: {},
            // 表单校验
            rules: {},
            optFrom: {},
            dataColumnList: [],

        };
    },
    watch: {
        "queryParams.currentPage"(val) {
            this.selectDataListButton();
        },
        "queryParams.pageSize"(val) {
            this.selectDataListButton();
        },
    },
    created() {
        this.selectDataListButton();
    },
    methods: {
        selectDataListButton() {
            botsListApi(this.queryParams).then(res => {
                this.dataColumnList = res.result.records
            })
        },
        getCurrentRow(row) {
            // 获取选中数据   row表示选中这一行的数据，可以从里面提取所需要的值
            this.ids = row.id;
            this.optFrom = row;
        },
        addButton() {
            this.$refs.notificationbotsFrom.show(null, 1);
        },
        editButton(row) {
            this.$refs.notificationbotsFrom.show(row, 2);
        },
        delectButton() {
            if (this.ids == null) return this.$message.error("请选择有效的一列");

        },
        selectButton(row) {
            this.$refs.notificationbotsFrom.show(row, 3);
        },
        primaryRest() {
            this.$refs['queryParams'].resetFields();
            this.selectDataListButton();
        },
        botsTypeFrom(row) {
            console.log(row)
            if (row.type == 'dingding') {
                return '钉钉'
            }
        },
        setInfomaNotificaButton(row){
            this.$refs.informaNotificaFrom.show(row, 1);
        }
    }
};
</script>



