<template>
    <div>
        <el-dialog :title="title" :before-close="handleClose" :visible.sync="dialog" direction="rtl"
            custom-class="demo-drawer" ref="drawer" size="60%">
            <el-form :model="addEditForm" label-width="100px" ref="addEditForm">
                <el-row :gutter="24">
                    <el-col :md="8" :sm="8">
                        <el-form-item label="类别" prop="type" >
                            <el-select v-model="addEditForm.type" placeholder="请选择" @change="AddSelectDept" :disabled="this.catType==3">
                                <el-option v-for="item in botTypeList" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :md="8" :sm="8">
                        <el-form-item label="发送类型" prop="sendType">
                            <el-select v-model="addEditForm.sendType" placeholder="请选择" :disabled="this.catType==3">
                                <el-option v-for="item in sendTypeList" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :md="8" :sm="8">
                        <el-form-item label="名字" prop="name">
                            <el-input v-model="addEditForm.name" :disabled="this.catType==3"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :md="8" :sm="8">
                        <el-form-item label="加密模式" prop="sendModel">
                            <el-select v-model="addEditForm.sendModel" placeholder="请选择" :disabled="this.catType==3">
                                <el-option v-for="item in sendModelList" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :md="8" :sm="8">
                        <el-form-item label="内容类型" prop="contentType">
                            <el-select v-model="addEditForm.contentType" placeholder="请选择" @change="AddSelectDept" :disabled="this.catType==3">
                                <el-option v-for="item in contentTypeList" :key="item.value" :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="24">
                    <el-col :md="12" :sm="12">
                        <el-form-item label="机器地址" prop="sendTypeUrl">
                            <el-input v-model="addEditForm.sendTypeUrl" :disabled="this.catType==3"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :md="12" :sm="12">
                        <el-form-item label="特定字段" prop="sendSpecific">
                            <el-input v-model="addEditForm.sendSpecific" placeholder="如果是关键字模式：则输入关键字；否则输入加密字段" :disabled="this.catType==3"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>


                <el-row :gutter="24">
                    <el-col :md="12" :sm="12">
                        <el-form-item label="发送标题" prop="sendTitle">
                            <el-input v-model="addEditForm.sendTitle" :disabled="this.catType==3"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :md="12" :sm="12">
                        <el-form-item label="发送周期" prop="sendFrequency">
                            <el-input v-model="addEditForm.sendFrequency" disabled></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="24">
                    <el-col :md="24" :sm="24">
                        <el-form-item label="模板内容" prop="templateContent">
                            <el-input v-model="addEditForm.templateContent" type="textarea" :disabled="this.catType==3"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer" v-if="this.catType!=3">
                <el-button @click="cancelForm">取 消</el-button>
                <!-- @click="$refs.drawer.closeDrawer()" -->
                <el-button type="primary" @click="addFormButton()" :loading="loading">{{ loading ? '提交中 ...' : '确 定'
                }}</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    saveOrUpdateBotsApi,
    selectBotsByIdApi
} from "@/api/work/bots/bots.js";
export default {
    data() {
        return {
            dialog: false,
            loading: false,
            editShow: false,
            title: null,
            addEditForm: {
            },
            timer: null,
            ids: null,
            botTypeList: [{
                value: 'dingding',
                label: "钉钉"
            }],
            sendTypeList: [{
                value: '1',
                label: "通知"
            }],
            sendModelList: [{
                value: 'keywords',
                label: "关键字"
            }, {
                value: 'encode',
                label: "加密"

            }],
            contentTypeList: [{
                value: 'text',
                label: "文本"
            }, {
                value: 'mark',
                label: "模版"

            }],
            catType:1
        };
    },
    methods: {
        show(row, type) {
            console.log('rsrs',row)
            this.catType=type
            this.dialog = true;
            if (type == 2) {
                this.selectByIdButton(row);
                this.editShow = true;
                this.title = "编辑";
            }
            if (type == 3) {
                this.selectByIdButton(row);
                this.editShow = true;
                this.title = "查看";
            }
            if (type == 1) {
                this.title = "新增";
            }
        },
        handleClose(done) {
            if (this.loading) {
                return;
            }
            this.$confirm("关闭")
                .then(_ => {
                    this.cancelForm();
                    this.dialog = false;
                })
                .catch(_ => { });
        },
        cancelForm() {
            this.loading = false;
            this.dialog = false;
            this.catType=1
            this.editShow = false;
            this.$refs['addEditForm'].resetFields();
            this.addEditForm={}
            this.$parent.selectDataListButton();
        },
        addFormButton() {
            saveOrUpdateBotsApi(this.addEditForm).then(res => {
                this.cancelForm();
            });
        },
        //查询详情
        selectByIdButton(row) {
            selectBotsByIdApi(row.id).then(res=>{
                this.addEditForm=res.result
                this.addEditForm.id=row.id
                console.log('rsrs',row)
            })
        },
        addDialogClosed() {
            // 对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
            this.$refs['addEditForm'].resetFields();
        },
        AddSelectDept(row) {
            console.log(row)
        }
    }
};
</script>
<style scoped></style>



